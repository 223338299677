<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    xAxisLabel: Array,
    dataset: Object,
    color: String,
    unit: String
  },
  mounted() {
    const vm = this
    this.renderChart(
      {
        labels: this.xAxisLabel,
        datasets: [
          {
            label: this.dataset.label,
            backgroundColor: this.color,
            data: this.dataset.data,
          
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false, scales: {
              xAxes: [{
                  barThickness: 7,
              }],
            yAxes: [{
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {
                        return value + ' ' + vm.unit;
                    },
                    precision: 5
                }
            }]
          } }
    );
  }
};
</script>
