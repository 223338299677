<script>

import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null
    };
  },
  props:{
    dataset: Object,
    color: String,
    labels: Array,
    xAxisLabel: Array,
    unit: String
  },
  mounted() {
    const vm = this
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    console.log(typeof(this.xAxisLabel))
    this.gradient.addColorStop(0, "rgba(41, 98, 255, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(41, 98, 255, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    // console.log([...this.dataset.data.keys()])
    this.renderChart(
      {
        labels: this.xAxisLabel,
        datasets: [
          {
            label: this.dataset.label,
            borderColor: this.color,
            pointBackgroundColor: "white",
            borderWidth: 2,
            backgroundColor: this.gradient,
            data: this.dataset.data
          }
        ],
        
      },
      { 
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                  fontSize: 10
              }
            }],
            yAxes: [{
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {
                        return value + ' ' + vm.unit;
                    },
                    precision: 5
                }
            }]
        }
      }
    );
  }
};
</script>